// React
import React, { useState, useEffect } from 'react';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages

// Utilities

// Components
import Story from 'components/_stories/story';

const AllStoriesComponent = ({
    tags,
    stories,
    pageContext,
    location,
    parentSlug,
}) => {
    const { labels } = pageContext;

    // Set tags
    const allTags = [labels.PAGES.STORIES.ALL_TAGS, ...tags.map(t => t.name)];
    const [tag, setTag] = useState(allTags[0]);

    // Stories
    const [visibleStories, setVisibleStories] = useState(stories);
    useEffect(() => {
        if (tag === labels.PAGES.STORIES.ALL_TAGS) {
            setVisibleStories(stories);
        } else {
            setVisibleStories(
                stories.filter(story => story.tags.indexOf(tag) > -1)
            );
        }
    }, [tag]);

    return (
        <>
            <AllStories>
                <Tags>
                    {allTags.map(t => (
                        <Tag
                            onClick={() => setTag(t)}
                            key={t}
                            {...{ active: t === tag ? 1 : 0 }}>
                            {t}
                        </Tag>
                    ))}
                </Tags>
                <Stories>
                    {visibleStories.map(story => (
                        <Story
                            key={story.id}
                            {...{ story, pageContext, location, parentSlug }}
                        />
                    ))}
                </Stories>
            </AllStories>
        </>
    );
};

const AllStories = styled.div`
    display: block;
    color: ${s.color('black').default()};
`;

const Tags = styled.div`
    padding: 0 0 1em;
    display: flex;
    flex-wrap: wrap;
    border-bottom: ${`1px solid ${s.color('black').alpha(0.7)}`};
    margin-bottom: 3em;
    white-space: nowrap;
`;

const Tag = styled.span`
    ${s.fonts.style('h12')};
    opacity: ${props => (props.active === 1 ? 1 : 0.25)};
    transition: opacity 400ms;
    cursor: pointer;
    margin-right: 2em;
`;

const Stories = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

export default AllStoriesComponent;
