// React
import React from 'react';

// Gatsby
import { useStaticQuery, graphql } from 'gatsby';

// Emotion / styling
import styled from '@emotion/styled';
import s from 'src/styles';

// Packages

// Utilities
import { localize } from 'utilities';
import { useTheme } from 'hooks';

// Components
import SEO from 'components/seo';
import { PageNavigation } from 'components/pageNavigation';
import PageIntroduction from 'components/pageIntroduction';
import AllStories from 'components/_stories/allStories';

const StoriesPageComponent = props => {
    const {
        content: { title, seoMetaTags, stories, tags, introduction, slug },
        location,
        pageContext,
        pageContext: { locale },
    } = props;

    // Set theme
    const theme = useTheme(s.color('white'));

    // Format story tags
    const formattedStories = stories.map(s => ({
        ...s,
        tags: s.tags.map(t => t.name),
    }));

    return (
        <>
            <s.layout.PageContainer>
                <SEO {...{ seoMetaTags, locale }} />
                <ContentWrapper>
                    <PageNavigation {...{ theme }}>
                        <span>{title}</span>
                    </PageNavigation>
                    <PageIntroduction {...{ content: introduction }} />
                    <AllStories
                        {...{
                            tags,
                            parentSlug: slug,
                            stories: formattedStories,
                            location,
                            pageContext,
                        }}
                    />
                </ContentWrapper>
            </s.layout.PageContainer>
        </>
    );
};

const ContentWrapper = styled.div`
    ${s.responsive.property('padding-top', {
        0: 100,
        30: 140,
    })};
`;

const NamedStoriesPageComponent = props => {
    const {
        datoCmsPageStory,
        allDatoCmsBlogPost,
        allDatoCmsTag,
    } = useStaticQuery(graphql`
        query {
            datoCmsPageStory {
                slug
                title
                introduction
                seoMetaTags {
                    ...SEO
                }
            }
            allDatoCmsBlogPost(
                sort: { fields: [date], order: DESC }
                filter: { hideFromStories: { eq: false } }
            ) {
                edges {
                    node {
                        ...BlogPostShort
                    }
                }
            }
            allDatoCmsTag(sort: { fields: [position], order: ASC }) {
                edges {
                    node {
                        position
                        locale
                        name
                    }
                }
            }
        }
    `);

    return (
        <StoriesPageComponent
            {...{
                content: {
                    ...datoCmsPageStory,
                    stories: localize(allDatoCmsBlogPost, 'en'),
                    tags: localize(allDatoCmsTag, 'en'),
                },
                ...props,
            }}
        />
    );
};

export default NamedStoriesPageComponent;
